/* eslint-disable react/jsx-no-target-blank */
import "./classDesktop.scss";
import { BullseyeCategory, BullseyeEvent } from "../../data/types";
import { classLanguage, classEnrollURL, classAtp, classCourseCategory, classRegion, classTimezone, classFormat, classLocation, classGuaranteed } from "../../utils/classHelpers";
import moment from "moment";
import { getAttributeValue } from "../../utils/bullseyeUtils";

type Props = {
  content: BullseyeEvent,
  allCourses: BullseyeCategory[]
}

/**
 * A component that represents a single class rendered for large screens.
 */
export default function ClassDesktop({content, allCourses}: Props) {
  return (
    <tr className="class-desktop">
      <td className="name">{renderClassCourseLink(content, allCourses)}</td>
      <td>{renderClassDates(content)}</td>
      <td>{renderClassAtp(content)}</td>
      <td>{renderClassButton(content)}</td>
      <td>{classRegion(content)}</td>
      <td>{classTimezone(content)}</td>
      <td>{classFormat(content)}</td>
      <td>{classLanguage(content)}</td>
      <td>{classLocation(content)}</td>
      <td>{classGuaranteed(content) ? "Yes" : "No"}</td>
    </tr>
  );
};

export function renderClassCourseLink(classContent: BullseyeEvent, allCourses: BullseyeCategory[]) {
  const course = classCourseCategory(classContent, allCourses);
  const courseName = course ? course.CategoryData : '';
  const courseUrlAttrId = parseInt(process.env.REACT_APP_BULSEYE_ATTRID_COURSE_URL!);
  let courseUrl = course 
    ? getAttributeValue(course.Attributes, courseUrlAttrId)
    : '';

  return courseUrl 
    ? <a 
        className="text-nowrap" 
        href={courseUrl} 
        target="_blank">
        {courseName}
      </a>
    : courseName
}

export function renderClassAtp(classContent: BullseyeEvent) {
  const atp = classAtp(classContent);
  return atp && atp.Name;
}

export function renderClassButton(classContent: BullseyeEvent) {
  const url = classEnrollURL(classContent);
  return <a href={url} target="_blank" className="btn btn-primary">Enroll</a>
}

export function renderClassDates(classContent: BullseyeEvent) {
  return <>{formatDates(classContent.StartDate)}-<br />{formatDates(classContent.EndDate)}</>;
}

const formatDates = function(date: string) {
  return moment.utc(date).format('LL');
}
