import "./classListDesktop.scss";

import React from "react";
import { BullseyeCategory, BullseyeEvent } from "../../data/types";
import ClassDesktop from "./classDesktop";
import { Card } from "react-bootstrap";

type Props = {
  classes: BullseyeEvent[],
  courses: BullseyeCategory[]
}

/**
 * A form component that renders a class list.
 */
const ClassListDesktop = ({classes, courses}: Props) => {
  return (
    <Card className="class-list-desktop shadow-sm p-3">
      <table>
        <thead>
          <tr>
            <th>COURSE</th>
            <th>DATE(S)</th>
            <th>ATP</th>
            <th>ENROLL</th>
            <th>REGION</th>
            <th>TIME ZONE</th>
            <th>FORMAT</th>
            <th>LANGUAGE</th>
            <th>LOCATION</th>
            <th>GUARANTEED <br />TO RUN</th>
          </tr>
        </thead>
        <tbody>
          {classes.map(c => <ClassDesktop key={c.EventId} content={c} allCourses={courses} />)}
        </tbody>
      </table>
    </Card>
  );
};

export default ClassListDesktop;
