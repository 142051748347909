import Label from "../label/label";
import Select from "react-select";
import ErrorDisplay from "../errorDisplay/errorDisplay";
import { Controller } from "react-hook-form";

const AutocompleteInput = ({
  name,
  label,
  small = false,
  compact = false,
  control,
  errors = [],
  placeholder = "",
  required = false,
  onChange = () => {},
  options,
  isMulti = false,
  ...props
}) => {
  const styles = {
    valueContainer: (provided) => {
      return { 
        ...provided, 
        paddingRight: 0,
        paddingLeft: '3px',
      };
    },
    multiValue: (provided) => ({
      ...provided,
      fontFamily: 'Arial, Helvetica, sans-serif',
      flexShrink: 0,
      borderRadius: '0.2rem',
      overflow: 'hidden'
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    clearIndicator: (provided, state) => {
      return {
        ...provided,
        width: '1.5em',
        paddingRight: 0,
        color: 'var(--color-dark)',
        ':hover': {
          color: 'var(--color-primary)',
        }
      }
    },
    dropdownIndicator: (provided, state) => {
      return {
        ...provided,
        width: '2em',
        color: 'var(--color-dark)',
        ':hover': {
          color: 'var(--color-primary)',
        }
      }
    },
    option: (provided, state) => {
      return {
        ...provided,
        fontSize: '0.8rem',
        fontWeight: '600',
        color: state.isFocused || state.isSeleted ? '#fff' : provided.color
      }
    }
  }

  return (
    <div className="form-group">
      {!compact && <Label htmlFor={name} label={label} required={required} />}
      <Controller 
        control={control} 
        name={name}
        defaultValue=""
        render={({ field }) =>
          <Select
            name={name}
            onChange={(value) => {field.onChange(value)}}
            options={options}
            placeholder={!compact ? placeholder : label}
            isMulti={isMulti}
            styles={styles}
            theme={(theme) => ({
              ...theme,
              borderRadius: '0.25rem',
              colors: {
                ...theme.colors,
                primary25: "var(--color-primary)",
                primary50: "var(--color-primary)",
                primary: "var(--color-primary)",
              },
            })}
            className={` ${errors[name] ? "is-invalid" : ""} `}
            {...props}
          />
        }
      />

      {errors[name] && <ErrorDisplay errorMessage={errors[name]?.message} />}
    </div>
  );
};
export default AutocompleteInput;
