import { BullseyeCategory, BullseyeEvent, BullseyeLocation, Filters, MultiFilterOption } from "../data/types";
import { getAttributeValue } from './bullseyeUtils';
import moment from "moment";
import { sortBy, intersection, compact, uniq } from "lodash-es";
import { CachedEventSearch } from "../services/restEventSearchService";
import { getCategories } from "../services/restSearchService";
import http from "../services/httpService";
import { getEnv } from "./env";

export function classRegion(event: BullseyeEvent):string {
  return getAttributeValue(
    event.Attributes,
    parseInt(getEnv('ATTRID_REGION')!)
  );
}

export function classTimezone(event: BullseyeEvent):string {
  return getAttributeValue(
    event.Attributes,
    parseInt(getEnv('ATTRID_TIMEZONE')!)
  );
}

export function classLanguage(event: BullseyeEvent):string {
  return getAttributeValue(
    event.Attributes, 
    parseInt(getEnv('ATTRID_LANGUAGE')!)
  );
}

export function classFormat(event: BullseyeEvent):string {
  return getAttributeValue(
    event.Attributes,
    parseInt(getEnv('ATTRID_FORMAT')!)
  );
}

export function classLocation(event: BullseyeEvent):string {
  return getAttributeValue(
    event.Attributes, 
    parseInt(getEnv('ATTRID_LOCATION')!)
  );
}

export function classGuaranteed(event: BullseyeEvent):boolean {
  const value = getAttributeValue(
    event.Attributes, 
    parseInt(getEnv('ATTRID_GUARANTEED')!)
  );
  return value === 'True' ? true : false;
}

export function classEnrollURL(event: BullseyeEvent):string {
  return getAttributeValue(
    event.Attributes, 
    parseInt(getEnv('ATTRID_ENROLL_URL')!)
  );
}

export function classCourse(event: BullseyeEvent):string {
  return getAttributeValue(
    event.Attributes, 
    parseInt(getEnv('ATTRID_CLASS_COURSE')!)
  );
}

export function classCourseCategory(classData: BullseyeEvent, allCourses: BullseyeCategory[]):BullseyeCategory | null {
  const course = allCourses.find(category => { 
    return classCourse(classData).includes(category.CategoryName);
  });

  return course !== undefined ? course : null;
}

export function classAtp(classData: BullseyeEvent):BullseyeLocation | undefined {
  return classData.EventLocations[0] !== undefined ? classData.EventLocations[0] : undefined;
}

export function getDate(date: Date | string):Date {
  return new Date(moment.utc(date).format('yyyy-MM-DD'));
}

export function getCurrentClasses(classes: BullseyeEvent[]): BullseyeEvent[] {
  const currentDate = new Date(moment.utc().format('yyyy-MM-DD'));
  return classes.filter((c) => getDate(c.StartDate) < currentDate ? false : true);
}

export function orderClasses(classes: BullseyeEvent[], allCourses: BullseyeCategory[]): BullseyeEvent[] {
  return sortBy(classes, [
    c => classCourseCategory(c, allCourses)?.CategoryData,
    c => getDate(c.StartDate)
  ])
}

// @todo The entire logic looks complex. Consider simplifying.
export function filterClasses(classes: BullseyeEvent[], filters: Filters) {
  const startDate = filters.startDate !== undefined ? getDate(filters.startDate) : undefined;
  const endDate = filters.endDate !== undefined 
    ? getDate(filters.endDate)
    : startDate !== undefined
      ? startDate
      : undefined;

  return classes.filter((c) => {
    const eventStartDate = c.StartDate !== undefined ? getDate(c.StartDate) : undefined;
    if ((eventStartDate !== undefined && startDate !== undefined && endDate !== undefined) && !(eventStartDate >= startDate && eventStartDate <= endDate)) {
      return false;
    }
    if (!matchesMultiFilter(classAtp(c)?.Id.toString(), filters.atp)) {
      return false;
    }
    if (!matchesMultiFilter(classRegion(c), filters.region)) {
      return false;
    }
    if (!matchesMultiFilter(classTimezone(c), filters.timezone)) {
      return false;
    }
    if (!matchesMultiFilter(classRegion(c), filters.region)) {
      return false;
    }
    if (!matchesMultiFilter(classFormat(c), filters.format)) {
      return false;
    }
    if (!matchesMultiFilter(classLocation(c), filters.location)) {
      return false;
    }
    if (!matchesMultiFilter([classLanguage(c)], filters.language)) {
      return false;
    }
    if (filters.guaranteed === true && !classGuaranteed(c)) {
      return false;
    }
    if (filters.course?.length && filters.course?.filter(courseName => classCourse(c).includes(courseName)).length === 0) {
      return false;
    }
    return true;
  });
}

function matchesMultiFilter(valueToCheck: string | string[] | undefined, chosenFilterOptions: MultiFilterOption[] | undefined) {
  if (valueToCheck === undefined) return false;

  if (Array.isArray(chosenFilterOptions) && chosenFilterOptions.length > 0) {
    const chosenFilterValues = chosenFilterOptions.map(o => o.value.toString());
    return Array.isArray(valueToCheck) 
      ? intersection(chosenFilterValues, valueToCheck).length > 0
      : chosenFilterValues.includes(valueToCheck)
  }

  return true;
}

export async function loadEvents(): Promise<any> {
  return CachedEventSearch({
    CountryId: 1, 
    PostalCode: '70001',
    Radius: 6000,
    StartIndex: 0,
    PageSize: 1200
  });
}

export async function loadCategories():Promise<BullseyeCategory[]> {
  http.setSecurityParameters(
    process.env.REACT_APP_CLIENT_ID,
    process.env.REACT_APP_API_KEY
  );
  return getCategories();
}

export function getAttribute(event: BullseyeEvent, attrId: number) {
  return event.Attributes.find(a => a.AttributeId === attrId);
}

export function uniqAttrValues(
  events: BullseyeEvent[], 
  attrId: number
): string[] {
  const attrVals = events.map(c => getAttributeValue(c.Attributes, attrId));
  return compact(uniq(attrVals)).sort();
}