import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App.tsx";
import { HelmetProvider } from "react-helmet-async";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://7691d2809c1e4c0ead996a21cc36f6c9@o445828.ingest.sentry.io/6466638",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  environment: "production",
  tracesSampleRate: 0.1,
});

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
