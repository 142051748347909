import "./App.scss";
import { Container } from "react-bootstrap";
import { useEffect, useMemo, useState } from "react";
import ClassFilters from "./components/classFilters/classFilters";
import ClassList from "./components/classList/classList";
import { BullseyeCategory, BullseyeEvent, Filters } from "./data/types";
import BullseyeLoader from "./components/bullseyeLoader/bullseyeLoader";
import Counter from "./components/counter/counter";
import { filterClasses, getCurrentClasses, loadEvents, orderClasses, loadCategories } from "./utils/classHelpers";
import { getEnv } from "./utils/env";

export default function App () {
  // Load Courses (Bullseye Categories).
  const [courses, setCourses] = useState<BullseyeCategory[]>();
  useEffect(() => {
    loadCategories().then(categories => {
      setCourses(categories.filter(c => c.ParentCategoryId && c.ParentCategoryId.toString() === getEnv('CATID_COURSE')))
    })
  }, []);

  // Load classes.
  const [classes, setClasses] = useState<BullseyeEvent[]>();
  useEffect(() => {
    courses !== undefined && loadEvents()
    .then((response) => {
      const currentClasses = getCurrentClasses(response.ResultList);
      setClasses(orderClasses(currentClasses, courses));
    });
  }, [courses]);

  // Filter classes based on selection.
  const [filters, setFilters] = useState<Filters>({});
  const filteredClasses = useMemo(
    () => (classes && filters) ? filterClasses(classes, filters) : [], 
    [filters, classes]
  )

  // Get default course from URL.
  useEffect(() => {
    if (Array.isArray(courses)) {
      let params = new URLSearchParams(document.location.search);
      const defaultCourse = courses.find(c => c.CategoryId.toString() === params.get('category'));
      defaultCourse && setFilters({course: [defaultCourse.CategoryName], ...filters});
    }
  }, [courses])

  return (
    <Container fluid className="app py-3">
      { classes !== undefined && courses !== undefined
        ? <>
            <ClassFilters 
              courses={courses} 
              classes={classes} 
              defaultValues={filters}
              onChange={(f) => setFilters(f)} 
            />
            <Counter number={filteredClasses.length} />
            {filteredClasses.length > 0 && <ClassList 
              classes={filteredClasses} 
              courses={courses}
            />}
          </>
        : <BullseyeLoader />
      }
    </Container>
  );
}