import "./classListMobile.scss";
import ClassMobile from "./classMobile";
import { BullseyeCategory, BullseyeEvent } from "../../data/types";

type Props = {
  classes: BullseyeEvent[],
  courses: BullseyeCategory[]
}

/**
 * A form component that renders a class list for small screen.
 */
const ClassListMobile = ({classes, courses}: Props) => {
  return (
    <div className="class-list-mobile row">
      {classes.map(c => <ClassMobile key={c.EventId} content={c} allCourses={courses} />)}
    </div>
  );
};

export default ClassListMobile;
