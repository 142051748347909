import "./classMobile.scss";
import "twix";
import { Card } from "react-bootstrap";
import { BullseyeCategory, BullseyeEvent } from "../../data/types";
import { classFormat, classGuaranteed, classLanguage, classLocation, classRegion, classTimezone } from "../../utils/classHelpers";
import { renderClassButton, renderClassCourseLink, renderClassAtp } from "./classDesktop";
import moment from "moment";

type Props = {
  content: BullseyeEvent,
  allCourses: BullseyeCategory[]
}

/**
 * A component that represents a single class rendered for small screens.
 */
const ClassMobile = ({content, allCourses}: Props) => {
  const language = classLanguage(content);
  const location = classLocation(content);

  return (
    <div className="class-mobile col-sm-12 mb-2 d-sm-flex align-items-stretch">
      <Card className="shadow-sm w-100 p-3">
        <h3 className="name mb-0">{ renderClassCourseLink(content, allCourses) }</h3>
        <div className="atp mb-2">{ renderClassAtp(content) }</div>
        <div className="mb-2 basic-details">{ language }</div>
        <div className="mb-1">
          <span className="label">Date(s):</span>
          <span className="value">{ formatDates(content.StartDate, content.EndDate) }</span>
        </div>
        <div className="mb-1">
          <span className="label">Region:</span>
          <span className="value">{ classRegion(content) }</span>
        </div>
        <div className="mb-1">
          <span className="label">Time Zone:</span>
          <span className="value">{ classTimezone(content) }</span>
        </div>
        <div className="mb-1">
          <span className="label">Format:</span>
          <span className="value">{ classFormat(content) }</span>
        </div>
        {location && <div className="flex-grow-1">
          <span className="label">Location:</span>
          <span className="value">{ location }</span>
        </div>}
        <div className="mb-1">
          <span className="label">Guaranteed to&nbsp;Run:</span>
          <span className="value">{ classGuaranteed(content) ? "Yes" : "No" }</span>
        </div>
        <div className="mt-3">
          { renderClassButton(content) }
          </div>
      </Card>
    </div>
  );
};

export default ClassMobile;

const formatDates = function(start: Date | string, end: Date | string) {
  return moment.utc(start).twix(end).format({
    monthFormat: "MMMM",
    hideTime: true,
    implicitYear: false
  });
}
