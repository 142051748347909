import React from 'react'

type Props = {
  number: number
}

export default function Counter(props: Props) {
  return <div className="counter">
    { props.number === 1
      ? '1 Result Found'
      : `${props.number} Results Found`
    } 
  </div>
}