import http from "./httpService";

const endpointPath = "RestSearch.svc";

const categoryGetMethod = "GetCategories";
const locationGetMethod = "GetLocation";
const location2GetMethod = "GetLocation2";
const doSearch2GetMethod = "DoSearch2";
const countryListGetMethod = "GetCountryList";
const getLocationListGetMethod = "GetLocationList";
const countryStatesListGetMethod = "GetStatesByCountry";
const getRegion1ListGetMethod = "GetRegion1List";
const logSearchRsltwReq = "LogSearch";
const getClientMappingSettings = "RestSearch.svc/GetClientMappingSettings";
//const ipAdressGetMethod = "https://www.cloudflare.com/cdn-cgi/trace";
const getLandingPageConfigurationGetMethod = "GetLandingPageConfiguration";
const getCategoryTreeGetMethod = "GetCategoryTree";
const getParseInputtedGetMethod = "ParseInputtedLocationSearchData";
const getAllCountriesGetMethod = "GetAllCountries";

function getCategoryTreeURL() {
  return `${endpointPath}/${getCategoryTreeGetMethod}?ReturnAllCategories=true`;
}
export async function getCategoryTree() {
  http.setSecurityParameters();

  const categories = await http.get(getCategoryTreeURL());
  return categories.data;
}
function getParseInputtedURL(countryId, inputtedData) {
  const bullseyeApiKey = process.env.REACT_APP_BUL_API_KEY;

  return `${endpointPath}/${getParseInputtedGetMethod}?InputtedData=${inputtedData}&CountryID=${countryId}&APIKey=${bullseyeApiKey}`;
}
function getAllCountriesURL() {
  const bullseyeApiKey = process.env.REACT_APP_BUL_API_KEY;

  return `${endpointPath}/${getAllCountriesGetMethod}?APIKey=${bullseyeApiKey}`;
}
function getLandingPageConfigurationURL(
  interfaceName,
  interfaceId,
  landingPageId,
  isPreview
) {
  let parameters = "";

  if (interfaceId) {
    parameters += `interfaceId=${interfaceId}`;
  } else if (interfaceName) {
    parameters += `interfaceName=${interfaceName}`;
  }
  if (landingPageId && parameters) {
    parameters += `&landingPageId=${landingPageId}`;
  }
  if (isPreview) {
    parameters += `&preview=true`;
  }

  return `${endpointPath}/${getLandingPageConfigurationGetMethod}?${parameters}`;
}
export async function getLandingPageConfiguration({
  interfaceName,
  interfaceId,
  landingPageId,
  isPreview,
}) {
  const config = await http.get(
    getLandingPageConfigurationURL(
      interfaceName,
      interfaceId,
      landingPageId,
      isPreview
    ),
    { params: [] }
  );
  return config.data;
}
function getLocationListURL(
  countryIds,
  action,

  isSEO,
  isProxy,
  locationIdentifier,
  callback,
  categoryIds,
  returnOnlyInternetLocations
) {
  let parameters = [];

  if (countryIds) {
    parameters.push(`countryIds=${countryIds}`);
  }

  if (action) {
    parameters.push(`action=${action}`);
  }

  if (categoryIds) {
    parameters.push(`categoryIds=${categoryIds}`);
  }

  if (isSEO) {
    parameters.push(`isSEO=${isSEO}`);
  }

  if (isProxy) {
    parameters.push(`isProxy=${isProxy}`);
  }

  if (locationIdentifier) {
    parameters.push(`locationIdentifier=${locationIdentifier}`);
  }

  if (callback) {
    parameters.push(`callback=${callback}`);
  }

  if (returnOnlyInternetLocations) {
    parameters.push(
      `ReturnOnlyInternetLocations=${returnOnlyInternetLocations}`
    );
  }
  return `${endpointPath}/${getLocationListGetMethod}?${parameters.join("&")}`;
}
function categoryUrl() {
  return `${endpointPath}/${categoryGetMethod}?ReturnAllCategories=true`;
}
function locationUrl(id) {
  return `${endpointPath}/${locationGetMethod}?LocationId=${id}`;
}
function location2Url(id, thirdpartyId, landingPageId) {
  const param = id ? `LocationId=${id}` : `ThirdPartyId=${thirdpartyId}`;
  return `RestLocation.svc/${location2GetMethod}?${param}&LandingPageID=${landingPageId}`;
}
function locationThirdpartyUrl(id) {
  return `${endpointPath}/${locationGetMethod}?ThirdPartyId=${id}`;
}
function countryListUrl() {
  return `${endpointPath}/${countryListGetMethod}`;
}
function countryStatesListUrl(countryId) {
  return `${endpointPath}/${countryStatesListGetMethod}?CountryId=${countryId}`;
}
function LogSearchRsltwReqURL(
  locationId,
  responseType,
  searchReqLogId,
  attributeId,
  attributeTypeId
) {
  let endpoint = `${endpointPath}/${logSearchRsltwReq}?locationID=${locationId}`;

  if (responseType) {
    endpoint += `&responseType=${responseType}`;
  }

  if (searchReqLogId) {
    endpoint += `&SearchLogReqId=${searchReqLogId}`;
  }

  if (attributeId) {
    endpoint += `&AttributeId=${attributeId}`;
  }

  if (attributeTypeId) {
    endpoint += `&AttributeType=${attributeTypeId}`;
  }

  return endpoint;
}

export async function getCategories() {
  http.setSecurityParameters();

  const categories = await http.get(categoryUrl());
  return categories.data;
}
export async function getParseInputted(
  countryId,
  inputtedData,
  validateState = false
) {
  http.clearSecurityParameters();
  let receivedInput = inputtedData;

  // I am not sure about this, is not very elegant solution :(
  // We are trying to include the comma automatically when the input
  // doesn't have the comma and the last word is 2 letters, we are assing
  // is a state.
  if (
    validateState &&
    inputtedData.includes(" ") &&
    !inputtedData.includes(",")
  ) {
    let words = inputtedData.split(" ");
    if (words[words.length - 1].length === 2) {
      words[words.length - 1] = "," + words[words.length - 1];

      receivedInput = words.join(" ");
    }
  }

  const parsedData = await http.get(
    getParseInputtedURL(countryId, receivedInput)
  );
  return parsedData.data;
}
export async function getAllCountries() {
  http.clearSecurityParameters();

  const countries = await http.get(getAllCountriesURL());
  return countries.data;
}

export async function getLocationList(
  clientId,
  apiKey,
  countryIds,
  action,

  isSEO,
  isProxy,
  locationIdentifier,
  callback,
  categoryIds,
  returnOnlyInternetLocations
) {
  if (apiKey && clientId) {
    http.setSecurityParameters(clientId, apiKey);
  } else return {};

  const locations = await http.get(
    getLocationListURL(
      countryIds,
      action,
      isSEO,
      isProxy,
      locationIdentifier,
      callback,
      categoryIds,
      returnOnlyInternetLocations
    )
  );

  return JSON.parse(locations.data);
}

export async function getSearchLocation(id, thirdPartyId) {
  http.setSecurityParameters();

  if (id) {
    const location = await http.get(locationUrl(id));
    return location.data;
  } else {
    const location = await http.get(locationThirdpartyUrl(thirdPartyId));
    return location.data;
  }
}

export async function getSearchLocation2(id, thirdpartyId, landingPageId) {
  http.setSecurityParameters();

  if (id || thirdpartyId) {
    const location = await http.get(
      location2Url(id, thirdpartyId, landingPageId)
    );
    return location.data;
  }
  return null;
}

export function convertObjectToQueryString(searchParameters, currentURL) {
  currentURL = currentURL ? currentURL : "?";

  Object.keys(searchParameters).forEach((key) => {
    let objValue = searchParameters[key];

    if (objValue !== null && objValue !== undefined && objValue !== "") {
      if (typeof objValue === "object") {
        currentURL = convertObjectToQueryString(objValue, currentURL);
      } else currentURL += `${key}=${searchParameters[key]}&`;
    }
  });

  if (currentURL.substring(currentURL.length - 1) === "&") {
    return currentURL.slice(0, -1);
  }

  return currentURL;
}

function doSearch2Url(searchParameters) {
  let endpoint = `${endpointPath}/${doSearch2GetMethod}${convertObjectToQueryString(
    searchParameters
  )}`;

  return endpoint;
}

function getRegion1ListUrl(countryId) {
  return `${endpointPath}/${getRegion1ListGetMethod}?CountryId=${countryId}`;
}

export async function DoSearch2(searchParameters) {
  const { configClientSearch } = searchParameters;
  if (
    configClientSearch &&
    configClientSearch.apiKey &&
    configClientSearch.clientId
  ) {
    http.setSecurityParameters(
      configClientSearch.clientId,
      configClientSearch.apiKey
    );
  } else {
    http.setSecurityParameters();
  }

  delete searchParameters.configClientSearch;

  const locations = await http.get(doSearch2Url(searchParameters));
  return locations.data;
}

export async function GetRegion1List(countryId) {
  http.setSecurityParameters();

  const region1List = await http.get(getRegion1ListUrl(countryId));
  return region1List.data;
}

export async function GetCountryList() {
  http.setSecurityParameters();

  const location = await http.get(countryListUrl());
  return location.data;
}
export async function GetCountryStatesList(countryId) {
  http.setSecurityParameters();

  const location = await http.get(countryStatesListUrl(countryId));
  return location.data;
}

export async function LogSearchRsltwReq(
  locationId,
  responseType,
  searchReqLogId,
  attributeId,
  attributeTypeId
) {
  http.setSecurityParameters();
  await http.get(
    `${LogSearchRsltwReqURL(
      locationId,
      responseType,
      searchReqLogId,
      attributeId,
      attributeTypeId
    )}`
  );
}

export async function findClientMappingSettings() {
  http.setSecurityParameters();

  const location = await http.get(getClientMappingSettings);
  return location.data;
}
