import http from "./httpService";
import { convertObjectToQueryString } from "./restSearchService";

const endpointPath = "RestEventSearch.svc";

export async function EventSearch(params) {
  http.setSecurityParameters(
    process.env.REACT_APP_CLIENT_ID,
    process.env.REACT_APP_API_KEY
  );
  const query = convertObjectToQueryString(params);
  const response = await http.get(`${endpointPath}/EventSearch${query}`);
  return response.data;
}

export async function CachedEventSearch(params) {
  http.setSecurityParameters(
    process.env.REACT_APP_CLIENT_ID,
    process.env.REACT_APP_API_KEY
  );
  const env = process.env.REACT_APP_URL_API.includes("staging") ? "staging" : "production";
  const query = convertObjectToQueryString({...params, cache_seconds: 60 * 60, simplify: true, env});
  const url = `${process.env.REACT_APP_CACHE_URL}/${query}`;
  const response = await http.get(url);
  return response.data;
}

export async function GetCategories() {
  http.setSecurityParameters(
    process.env.REACT_APP_CLIENT_ID,
    process.env.REACT_APP_API_KEY
  );
  const response = await http.get(`${endpointPath}/GetCategories`);
  return response.data;
}