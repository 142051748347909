import "./classFilters.scss";

import React, { useMemo, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { addDays, subDays } from "date-fns";
import { BullseyeLocation, BullseyeEvent, Filters, BullseyeCategory, MultiFilterOption } from "../../data/types";
import { uniqBy, sortBy, groupBy } from "lodash-es";
import { uniqAttrValues } from "../../utils/classHelpers";
import { useForm, SubmitHandler } from "react-hook-form";
import AutocompleteInput from "../dynamicForm/autocompleteInput/autocompleteInput";
import { getEnv } from "../../utils/env";
import { getAttributeValue } from "../../utils/bullseyeUtils";

type Props = {
  defaultValues?: Filters
  classes: BullseyeEvent[],
  courses: BullseyeCategory[],
  onChange: (filters: Filters) => void
}

/**
 * A form component that allows filtering classes.
 */
const ClassFilters = ({ classes, courses, onChange, defaultValues }: Props) => {
  const { register, handleSubmit, control } = useForm<Filters>({defaultValues});
  
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const onDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start ? start : undefined);
    setEndDate(end ? end : undefined);    
  };
  const allowedDates = [
    { start: subDays(new Date(), 1), end: addDays(new Date(), 2 * 365) },
  ];

  const atps = useMemo<BullseyeLocation[]>(
    () => sortBy(uniqBy(classes.flatMap(c => c.EventLocations), 'Id'), 'Name'),
    [classes]
  );
  const atpOptions = atps.map(a => ({ label: a.Name, value: a.Id }));
  const languageOptions = useMemo(() => attrToFilterOptions(classes, 'ATTRID_LANGUAGE'), [classes]);
  const regionOptions = useMemo(() => attrToFilterOptions(classes, 'ATTRID_REGION'), [classes]);
  const timezoneOptions = useMemo(() => attrToFilterOptions(classes, 'ATTRID_TIMEZONE'), [classes]);
  const formatOptions = useMemo(() => attrToFilterOptions(classes, 'ATTRID_FORMAT'), [classes]);
  const locationOptions = useMemo(() => attrToFilterOptions(classes, 'ATTRID_LOCATION'), [classes]);

  const onFormSubmit: SubmitHandler<Filters> = function(data) {
    onChange({startDate, endDate, ...data})
  };

  const groupedCourses = groupBy(orderByName(courses), (c) => {
    const attributeId = parseInt(getEnv('ATTRID_COURSE_GROUP')!);
    return getAttributeValue(c.Attributes, attributeId);
  });

  return (
    <form 
      className="card class-filters shadow-sm p-3 pt-4 mb-3"
      onSubmit={handleSubmit(onFormSubmit)} 
    >
      <Row className="inputs">
        <Col className="form-group" lg="5" sm="12">
          <label htmlFor="form-date">Date(s)</label>
          <ReactDatePicker 
            name="date"
            id="form-date"
            className="form-control form-date"
            selectsRange
            startDate={startDate}
            endDate={endDate}
            onChange={onDateChange} 
            includeDateIntervals={allowedDates}
            isClearable={true}
          />
        </Col>
        <Col lg="5" sm="12" className="field-atp">
          <AutocompleteInput 
            name="atp"
            label="Authorized Training Partner (ATP)"
            control={control}
            isMulti
            options={atpOptions}
          />
        </Col>
        <Col lg="5" sm="12">
          <AutocompleteInput 
            name="region"
            label="Region"
            control={control}
            isMulti
            options={regionOptions}
          />
        </Col>
        <Col lg="5" sm="12">
          <AutocompleteInput 
            name="timezone"
            label="Time Zone"
            control={control}
            isMulti
            options={timezoneOptions}
          />
        </Col>
        <Col lg="5" sm="12">
          <AutocompleteInput 
            name="language"
            label="Language"
            control={control}
            isMulti
            options={languageOptions}
          />
        </Col>
        <Col lg="5" sm="12">
          <AutocompleteInput 
            name="format"
            label="Format"
            control={control}
            isMulti
            options={formatOptions}
          />
        </Col>
        <Col lg="5" sm="12">
          <AutocompleteInput 
            name="location"
            label="Available Locations (In Person/Hybrid Classes)"
            control={control}
            isMulti
            options={locationOptions}
          />
        </Col>
        <Col lg="5" sm="12" className="guaranteed">
          <div className="form-group">
            <div className="form-label">Guaranteed to Run</div>
            <div className="form-check-inline">
              <input type="checkbox" className="form-check-input" id="guaranteed" {...register("guaranteed")} /> 
              <label htmlFor="guaranteed" className="form-check-label">
                Find classes guaranteed to run with no minimum class size
              </label>
            </div>
          </div>
        </Col>
        <Col md="4" className="d-none d-lg-block order-lg-6">
          <Button type="primary" variant="dark" className="mb-4">Search</Button>
        </Col>
      </Row>
      <Row>
        <Col sm="12" className="form-label mb-2">Please select one or more courses:</Col>
      </Row>
      <Row>
        <div className="col-sm-24">
          <div className="row">
            {Object.keys(groupedCourses).map(groupName => 
              <Col sm="12" md="8" className="mb-1" key={groupName}>
                <div className="form-group-name">{groupName}</div>
                {groupedCourses[groupName].map(c => 
                  <div className="form-check-inline" key={c.CategoryId}>
                    <input className="form-check-input" type="checkbox" value={c.CategoryName} id={'course-'+c.CategoryId} {...register("course")} />
                    <label className="form-check-label" htmlFor={'course-'+c.CategoryId}>{courseChecboxTitle(c)}</label>
                  </div>
                )}
              </Col>
            )}
          </div>
        </div>
      </Row>
      <Row>
        <Col sm="6" className="mt-3 d-lg-none">
          <Button type="primary" variant="dark" className="mb-2">Search</Button>
        </Col>
      </Row>
    </form>
  );
};

export default ClassFilters;

const orderByName = function(categories: BullseyeCategory[]) {
  return sortBy(categories, (c) => c.CategoryName.toLowerCase());
}

const attrToFilterOptions = function(
  classes: BullseyeEvent[], 
  attrName: string
): MultiFilterOption[] {
  const attrId = parseInt(getEnv(attrName)!);
  return uniqAttrValues(classes, attrId).map(v => ({label: v, value: v}))
}

const courseChecboxTitle = function(course: BullseyeCategory) {
  const courseDuration = getAttributeValue(course.Attributes, parseInt(getEnv('ATTRID_COURSE_DURATION')!));
  // @ts-ignore
  return <>{course.CategoryName} <span className="course-duration">({courseDuration})</span></>;  
}