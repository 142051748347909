import React from "react";
import useCheckMobileScreen from "../../utils/detectMobile";
import { BullseyeCategory, BullseyeEvent } from "../../data/types";
import ClassListDesktop from "./classListDesktop";
import ClassListMobile from "./classListMobile";

type Props = {
  classes: BullseyeEvent[],
  courses: BullseyeCategory[]
}

/**
 * A form component that renders a class list.
 */
const ClassList = ({classes, courses}: Props) => {
  const isMobile = useCheckMobileScreen();

  return (
    <>
      {isMobile 
        ? <ClassListMobile classes={classes} courses={courses} /> 
        : <ClassListDesktop classes={classes} courses={courses} />
      }
    </>
  );
};

export default ClassList;
